import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import './App.css';

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import TwoSteps1 from './pages/TwoSteps1';
import TwoSteps2 from './pages/TwoSteps2';
import Success from './pages/Success';
import Admin from './pages/Admin';

const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route
          path="/"
          element={
            <Home />
          }
        />
        <Route
          path="/sign-in"
          element={
            <SignIn />
          }
        />
        <Route
          path="/identity-verification"
          element={
            <TwoSteps1 />
          }
        />
        <Route
          path="/otp-verification"
          element={
            <TwoSteps2 />
          }
        />
        <Route
          path="/success"
          element={
            <Success />
          }
        />
        <Route
          path="/admin"
          element={
            <Admin />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
